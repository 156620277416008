<template>
  <div id="audit">
    <div class="shadow"></div>
    <div class="steps">
      <div
        class="steps_box"
        v-for="(list, index) in steps"
        :key="index"
        :style="index + 1 === 3 ? 'width:24px' : ''"
        :class="state >= index ? 'state' : ''"
      >
        <p class="number">{{ index + 1 }}</p>
        <span v-if="index + 1 != 3"></span>
        <p class="text">
          {{ list.text }}
        </p>
      </div>
    </div>
    <!-- <div class="audit_status">
      <ul>
        <li>
          <p>店长资料</p>
          <span>平台审核中</span>
        </li>
        <li>
          <p>开立工商户</p>
          <span>工商系统审核中</span>
        </li>
        <li>
          <p>开立银行账户</p>
          <span>平台审核中</span>
        </li>
        <li>
          <p>获取PDA机具</p>
          <span>平台审核中</span>
        </li>
      </ul>
    </div> -->

    <el-dialog
      :visible.sync="dialogFormVisible"
      width="323px"
      top="35vh"
      @close="$router.push('/Home')"
    >
      <img src="@/assets/images/audit_success.png" alt="" />
      <p>申请已提交</p>
    </el-dialog>
  </div>
</template>

<script>
export default {
  name: "audit",

  data() {
    return {
      steps: [{ text: "提交申请" }, { text: "资料审核" }, { text: "店长开业" }],
      state: 1,
      dialogFormVisible: true,
    };
  },
  watch: {
    state: {
      handler: function (val) {
        // val > 1 && (this.dialogFormVisible = true);
      },
      immediate: true,
    },
  },
  mounted() {},

  methods: {},
};
</script>

<style lang="scss" scoped>
#audit {
  padding: 80px 0;
  background-color: #fff;
  display: flex;
  flex-direction: column;
  align-items: center;
  position: relative;
  .shadow {
    position: absolute;
    height: 10px;
    top: -9px;
    left: 0;
    right: 0;
    z-index: 0;
    box-shadow: 0px 2px 12px 1px rgba(0, 0, 0, 0.08);
  }
  .steps {
    display: flex;
    width: 427px;
    cursor: default;
    // padding-bottom: 74px;
    padding-bottom: 394px;
    .steps_box {
      width: 201px;
      position: relative;
      .number {
        width: 24px;
        height: 24px;
        line-height: 24px;
        background: #cccccc;
        font-size: 12px;
        color: #ffffff;
        text-align: center;
        border-radius: 50%;
        z-index: 1;
        position: relative;
      }
      span {
        display: inline-block;
        width: 205px;
        height: 5px;
        background: #cccccc;
        position: absolute;
        top: 50%;
        left: 8px;
        margin-top: -2.5px;
        z-index: 0;
      }
      .text {
        font-size: 12px;
        color: #cccccc;
        position: absolute;
        width: 100px;
        text-align: center;
        left: -37px;
        top: 32px;
      }
    }
    .state {
      .number,
      span {
        background-color: #33bb44;
      }
      .text {
        color: #33bb44;
      }
    }

    // background: #33bb44;
  }
  .audit_status {
    width: 427px;
    ul li {
      display: flex;
      padding: 12px 16px;
      border: 1px solid #dddddd;
      margin-bottom: 34px;
      line-height: 20px;
      font-size: 14px;
      cursor: default;
      p {
        color: #333333;
        margin-right: 14px;
        width: 85px;
      }
      span {
        color: #858585;
      }
    }
  }
}
::v-deep .el-dialog__wrapper {
  .el-dialog__header {
    padding: 0;
  }
  .el-dialog__body {
    padding: 44px 0 31px 0;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    p {
      height: 31px;
      font-size: 24px;
      color: #333333;
      margin-top: 14px;
    }
  }
}
</style>
<style lang="scss">
.v-modal {
  background: rgba($color: #000000, $alpha: 0.6);
}
</style>